import React, { useState } from "react";
import styled from "styled-components";
import ModalVideo from "react-modal-video";
import play from "../../images/videoButtons/play.png";

const BtnLink = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	width: 20.5rem;
	height: 5.35rem;
	border-radius: 1.5rem;
	background: #ff9259;
	color: #ffffff;
	font-family: "DM Sans", sans-serif;
	font-weight: bold;
	font-size: 1.3rem;
	line-height: 15px;
	cursor: pointer;
	transition: all 0.4s ease-in-out;
	img {
		width: 1.14rem !important;
		margin-right: 1rem;
	}
	&:hover {
		opacity: 0.7;
	}
	@media only screen and (max-width: 1284px) {
		min-width: 16rem;
		height: 4rem;
		border-radius: 1.25rem;
		font-size: 1.2rem;
	}
	@media only screen and (max-width: 576px) {
		width: auto;
		padding: 0 5%;
		font-size: 1.2rem;
	}
	@media only screen and (max-width: 340px) {
        font-size: 4vw;
	}
`;

const ExplainerButtonBig = (props) => {
	const [isOpen, setOpen] = useState(false);
	return (
		<>
			<ModalVideo
				channel="vimeo"
				autoplay
				isOpen={isOpen}
				videoId={props.videoId}
				muted={true}
				onClose={() => setOpen(false)}
			/>
			<BtnLink onClick={() => setOpen(true)}>
				<img src={play} alt="play button" />
				<p>Play Explainer video</p>
			</BtnLink>
		</>
	);
};

export default ExplainerButtonBig;
