import React from "react";
import SEO from "../components/seo/SEO";
import { Link } from "gatsby";
import { graphql } from "gatsby";
import VideoButton from "../components/styled/VideoButton";
import ExplainerButtonBig from "../components/styled/ExplainerButtonBig";
import Form from "../components/form/Form";
import LogoWhite from "../images/navbar/logoWhite.png";
import videoButton1 from "../images/videoButtons/videoButton1.png";

export const query = graphql`
	query($slug: String!) {
		pagesJson(slug: { eq: $slug }) {
			slug
			path
			backgroundImage {
				childImageSharp {
					original {
						src
					}
				}
			}
			title
			text
			list {
				listText
				listIcon {
					childImageSharp {
						original {
							src
						}
					}
				}
			}
			videoIdMain
			videoIdExplainer
		}
	}
`;

const Pages = ({ data }) => {
	const page = data.pagesJson.slug;
	const description = `This is ${page} reminder`;

	return (
		<>
			<SEO title={page} description={description} />
			<div className="createPagesWrapper">
				<div className="createPagesHomeWrapper">
					<div className="container">
						<nav className="createPagesNav">
							<div className="createPagesNavLogoWrapper">
								<Link to="/">
									<img
										src={LogoWhite}
										className="createpagesLogo"
										alt="create pages logo"
									/>
								</Link>
							</div>

							<div className="createPagesNavButtonWrapper">
								<Link to={data.pagesJson.path} className="createpagesGoHome">
									Back to homepage
								</Link>
							</div>
						</nav>

						<VideoButton
							videoId={data.pagesJson.videoIdMain}
							className="heroVideoButton"
							text={
								<div
									className="createPagesHeroBackgroundWrapper"
									style={{
										backgroundImage: `url(${data.pagesJson.backgroundImage.childImageSharp.original.src})`,
										backgroundPosition: "center",
										backgroundSize: "cover",
										backgroundRepeat: "no-repeat",
									}}
								>
									<img src={videoButton1} alt="videoButton" />
								</div>
							}
						/>

						<div className="createPagesAboutSection">
							<p className="createPagesTitle">{data.pagesJson.title}</p>

							<div className="createPagesAboutSectionText">
								<div className="createPagesAboutSectionMain">
	
	                                {data.pagesJson.text.map((item, index) => {
	                                    return (
											<p className="createPagesText" key={index}>{item}</p>
										)	
	
									})}
									<div className="createPagesPlayExpBtn">
										<ExplainerButtonBig videoId={data.pagesJson.videoIdExplainer}/>
									</div>
								</div>
								<div className="createPagesAboutSectionList">
									<ul>
										{data.pagesJson.list.map((item, index) => {
											return (
												<li key={index} className="createpagesListItem">
													<img
														className="createPagesIcons"
														src={item.listIcon.childImageSharp.original.src}
														alt={item.listText}
													/>
													<span>{item.listText}</span>
												</li>
											);
										})}
									</ul>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="container">
					<div className="createPagesFormSection" id="createPagesForm">
						<p className="titles-dark">Request More Info</p>
						<Form />
					</div>
				</div>
			</div>
		</>
	);
};

export default Pages;
