import React, { useState } from "react";
import styled from "styled-components";
import ModalVideo from "react-modal-video";

const BtnLink = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	background: transparent;
	color: #ff9259;
	font-family: "DM Sans", sans-serif;
	font-weight: bold;
	font-size: 1.3rem;
	line-height: 15px;
	cursor: pointer;
	transition: all 0.4s ease-in-out;

	&:hover {
		opacity: 0.7;
	}
	@media only screen and (max-width: 768px) {
		justify-content: center;
		margin-top: 3rem;
	}
`;

const VideoButton = (props) => {
	const [isOpen, setOpen] = useState(false);
	return (
		<>
			<ModalVideo
				channel="vimeo"
				autoplay
				isOpen={isOpen}
				videoId={props.videoId}
				muted= {true}
				onClose={() => setOpen(false)}
			/>
			<BtnLink onClick={() => setOpen(true)}>
			{props.text}
			</BtnLink>
		</>
	);
};

export default VideoButton;
